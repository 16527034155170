import axios from "axios";
import Player, { PlayerWP, WPImage } from "./player";
import { POSITION } from "./constants";
import { BatterStats, BatterStatsWP, PitcherStats, PitcherStatsWP, ScheduleWP, SeasonWP } from "./interfaces";

export interface Roster {
    ID: number,
    post_title: string,
    post_status: string,
    post_type: string,
    post_name: string,
}

export interface TeamACF {
    team_location: string,
    team_name: string,
    shorthand_team_name: string,
    team_logo: WPImage,
    roster: Roster[],
}

export interface TeamWP {
    id: number,
    slug: string,
    status: string,
    acf: TeamACF,
}

export async function getScheduledGame(id:string): Promise<ScheduleWP>{
    const url = `https://www.daseball.com/wp-json/wp/v2/schedule/${id}`;
    const response = await axios.get(url);
    const data = await response.data;
    return data;
}

export async function getSeasonForGame(id:string): Promise<SeasonWP>{
    const url = `https://www.daseball.com/wp-json/wp/v2/season?post=${id}`;
    const response = await axios.get(url);
    const data = await response.data;
    return data;
}

export default class Team {
    id: any;
    name = "";
    players: Player[] = [];
    bullpen: Player[] = [];
    stadium = "";
    stadiumImage = "";
    teamWP: TeamWP = {} as TeamWP;
    playersWP: PlayerWP[] = [];

    public printTeam() {
        console.log(this.name);
        console.log(this.players);
        console.log(this.bullpen);
    }

    private async getPlayer(id:number):Promise<PlayerWP>{
        const response = await axios.get(`https://www.daseball.com/wp-json/wp/v2/players/${id.toString()}`);
        return response.data;
    }

    private async getPlayerStats(id:number, season:string):Promise<any>{
        try{
            const response = await axios.get(`https://daseball.com/wp-json/daseball-api/v1/player-stats/${id.toString()}/${season}`);
            return response.data;
        }catch(error){
            console.error(error);
            return null;
        }
    }

    private async getPitcherStats(id:number, season:string):Promise<any>{
        try{
            const response = await axios.get(`https://daseball.com/wp-json/daseball-api/v1/pitcher-stats/${id.toString()}/${season}`);
            return response.data;
        }catch(error){
            console.error(error);
            return null;
        }
    }

    public async  getTeam(id:number,season:string){
        const response = await axios.get(`https://www.daseball.com/wp-json/wp/v2/teams/${id.toString()}`);
        this.teamWP = response.data;
        await this.getPlayers(this.teamWP.acf.roster,season);
        this.id = id;
        this.name = this.teamWP.acf.team_name;
    }

    private async getPlayers(roster:Roster[],season:string){
        for(let i = 0; i < this.teamWP.acf.roster.length; i++){
            const player:PlayerWP = await this.getPlayer(this.teamWP.acf.roster[i].ID);
            const playerStats = await this.getPlayerStats(this.teamWP.acf.roster[i].ID, season);
            const statAverages = await this.calculatePlayerStats(playerStats);
            
            //console.log("playerStats");
            //console.log(playerStats);
            this.playersWP.push(player);
            const playerConverted = this.convertPlayerWPToPlayer(player, statAverages);
            if(playerConverted.position == POSITION.PITCHER){
                const pitcherStats = await this.getPitcherStats(this.teamWP.acf.roster[i].ID, season);
                console.log(pitcherStats);
                if(pitcherStats != null){
                    console.log("pitcherAverages");
                    const pitcherAverages = await this.calculatePitcherStats(pitcherStats);
                    playerConverted.addPitcherAverages(pitcherAverages);
                    console.log(playerConverted.pitcherAverages);
                }
                this.bullpen.push(playerConverted);
            }
            if(this.players.length < 9){
                // TODO:need to be smarter about choosing the lineup
                this.players.push(playerConverted);
            }
            
        }
    }
    private async calculatePlayerStats(playerStats:BatterStatsWP[]):Promise<BatterStats | null>{
        if(!playerStats || playerStats.length == 0){
            return null;
        }
        const totals = {
            AB: 0,
            R: 0,
            H: 0,
            '2B': 0,
            '3B': 0,
            HR: 0,
            RBI: 0,
            BB: 0,
            SO: 0,
            HP: 0,
            SF: 0,
            SB: 0,
            AVG: 0.0,
        }
        playerStats.forEach((stat) => {
            totals.AB = totals.AB + Number(stat.AB);
            totals.R = totals.R + Number(stat.R);
            totals.H = totals.H + Number(stat.H);
            totals['2B'] = totals['2B'] + Number(stat['2B']);
            totals['3B'] = totals['3B'] + Number(stat['3B']);
            totals.HR = totals.HR + Number(stat.HR);
            totals.RBI = totals.RBI + Number(stat.RBI);
            totals.BB = totals.BB + Number(stat.BB);
            totals.SO = totals.SO + Number(stat.SO);
            totals.HP = totals.HP + Number(stat.HP);
            totals.SF = totals.SF + Number(stat.SF);
            totals.SB = totals.SB + Number(stat.SB);
            totals.AVG = totals.AVG + parseFloat(stat.H)/parseFloat(stat.AB);
        });
        const totalRecords = playerStats.length;
        const averages:BatterStats = {
            AB: totals.AB / totalRecords,
            R: totals.R / totalRecords,
            H: totals.H / totalRecords,
            '2B': totals['2B'] / totalRecords,
            '3B': totals['3B'] / totalRecords,
            HR: totals.HR / totalRecords,
            RBI: totals.RBI / totalRecords,
            BB: totals.BB / totalRecords,
            SO: totals.SO / totalRecords,
            HP: totals.HP / totalRecords,
            SF: totals.SF / totalRecords,
            SB: totals.SB / totalRecords,
            AVG: totals.AVG*1.0 / totalRecords,
            id: Number(playerStats[0].id),
            team: Number(playerStats[0].team),
            player: Number(playerStats[0].player),
            player_text: playerStats[0].player_text,

        }
        return averages;
    }

private async calculatePitcherStats(pitcherStats:PitcherStatsWP[]):Promise<PitcherStats | null>{
    if(!pitcherStats || pitcherStats.length == 0){
        return null;
    }
    const totals = {
        IP: 0.0,
        R: 0,
        H: 0,
        ER: 0,
        BB: 0,
        SO: 0,
        BF: 0,
        ERA: 0.0,
    }
    pitcherStats.forEach((stat) => {
        totals.IP = totals.IP + parseFloat(stat.IP);
        totals.R = totals.R + Number(stat.R);
        totals.H = totals.H + Number(stat.H);
        totals.ER = totals.ER + Number(stat.ER);
        totals.BB = totals.BB + Number(stat.BB);
        totals.SO = totals.SO + Number(stat.SO);
        totals.BF = totals.BF + Number(stat.BF);
        totals.ERA = totals.ERA + (9 * parseFloat(stat.ER))/parseFloat(stat.IP);
    });
    const totalRecords = pitcherStats.length;
    const averages:PitcherStats = {
        IP: totals.IP*1.0 / totalRecords,
        R: totals.R / totalRecords,
        H: totals.H / totalRecords,
        ER: totals.ER / totalRecords,
        BB: totals.BB / totalRecords,
        SO: totals.SO / totalRecords,
        BF: totals.BF / totalRecords,
        ERA: totals.ERA*1.0 / totalRecords,
        id: Number(pitcherStats[0].id),
        team: Number(pitcherStats[0].team),
        player: Number(pitcherStats[0].player),
        player_text: pitcherStats[0].player_text,
        game: Number(pitcherStats[0].game),
    }
    return averages;
}

    private convertPlayerWPToPlayer(player:PlayerWP, statAverages:(BatterStats | null)):Player{
        return new Player(player, statAverages);
    }

}