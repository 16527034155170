import { POSITION, BATS_THROWS, OUTCOME, PITCH_OUTCOME } from "./constants";
import { BatterStats, CardImage, PitcherStats } from "./interfaces";
// const mysql = require('mysql2');
// import { connection, runQuery } from "./mysql";



export interface WPImageSizes {
    thumbnail: string,
    'thumbnail-width': number,
    'thumbnail-height': number,
    medium: string,
    'medium-width': number,
    'medium-height': number,
    medium_large: string,
    'medium_large-width': number,
    'medium_large-height': number,
    large: string,
    'large-width': number,
    'large-height': number,
    '1536x1536': string,
    '1536x1536-width': number,
    '1536x1536-height': number,
    '2048x2048': string,
    '2048x2048-width': number,
    '2048x2048-height': number,
}

export interface WPImage {
    id: number,
    title: string,
    filename: string,
    filesize: number,
    url: string,
    link: string,
    alt: string,
    description: string,
    icon: string,
    width: number,
    height: number,
    sizes: WPImageSizes,
}

export interface PlayerACF {
    first_name: string,
    last_name: string,
    player_number: string,
    dob: string,
    height: string,
    weight: string,
    position_on_field: string,
    position: string,
    bats_throws: string,
    home_town: string,
    school: string,
    biography: string,
    walk_up_song: string,
    player_photo: WPImage,
    card_front: any,
    card_back: any,
    player_statistics: any,
    speed: string,
    fielding: string,
    hitting: string,
    throwing: string,
    power: string,
}
export interface PlayerWP {
    id: number,
    status: string,
    slug: string,
    acf: PlayerACF,
}

export default class Player {
    id: any;
    number: number;
    firstName: string;
    lastName: string;
    position: POSITION;
    home: string;
    birthDate: string;
    height: number;
    weight: number;
    bats: BATS_THROWS;
    throws: BATS_THROWS;
    description: string;
    image: string;
    cardImages: CardImage[];
    ERA?: number = 0;
    totalIP?: number = 0;
    totalEarnedRuns?: number = 0;
    totalHits?: number = 0;
    totalAtBats?: number = 0;
    AVG?: number = 0;
    cardFront?: string;
    cardBack?: string;
    batterGameStats?: BatterStats;
    batterAverages?: BatterStats;
    pitcherGameStats?: PitcherStats;
    pitcherAverages?: PitcherStats;
    hitting: number;
    throwing: number;
    speed: number;
    fielding: number;
    power: number;

    constructor(player:PlayerWP, batterAverages:(BatterStats | null)){
        const batsThrows = player.acf.bats_throws.split('/');
        const bats = batsThrows[0] == 'R' ? BATS_THROWS.RIGHT : BATS_THROWS.LEFT;
        const throws = batsThrows[1] == 'R' ? BATS_THROWS.RIGHT : BATS_THROWS.LEFT;
        const position:POSITION = Object.values(POSITION).find((pos) => pos == player.acf.position) || POSITION.SECOND_BASE;
        this.id = player.id;
        this.number = Number(player.acf.player_number);
        this.firstName = player.acf.first_name;
        this.lastName = player.acf.last_name;
        this.position = position;
        this.home = player.acf.home_town;
        this.birthDate = player.acf.dob;
        this.height = Number(player.acf.height);
        this.weight = Number(player.acf.weight);
        this.bats = bats;
        this.throws = throws;
        this.description = player.acf.biography;
        this.image = player.acf.player_photo.url;
        this.cardImages = [player.acf.card_front,player.acf.card_back];
        this.batterAverages = batterAverages || undefined;
        this.hitting = Number(player.acf.hitting);
        this.throwing = Number(player.acf.throwing);
        this.speed = Number(player.acf.speed);
        this.fielding = Number(player.acf.fielding);
        this.power = Number(player.acf.power);
    }
    
    updateERA(){
        // if(this.totalIP == 0){
        //     this.ERA = 0;
        // }else{
        //     this.ERA = (this.totalEarnedRuns / this.totalIP) * 9;
        // }
    }
    updateBattingAverage(){
        // if(this.totalAtBats == 0){
        //     this.AVG = 0;
        // }else{
        //     this.AVG = this.totalHits / this.totalAtBats;
        // }
    }

    addRunForPitcher(earnedRun: boolean){
        // this.pitcherGameStats.R = this.pitcherGameStats.R + 1;
        // //For now all runs will be earned runs
        // if(earnedRun){
        //     this.pitcherGameStats.ER = this.pitcherGameStats.ER + 1;
        // }
        // this.totalEarnedRuns = this.totalEarnedRuns + 1;
        // this.updateERA();
    }

    addRunForBatter(){
        // this.batterGameStats.R = this.batterGameStats.R + 1;
    }
    addRBIForBatter(){
        // this.batterGameStats.RBI = this.batterGameStats.RBI + 1;
    }

    printKeyStats(){
        console.log(`${this.firstName} ${this.lastName} #${this.number} ${this.position}`);
        console.log(`ERA: ${this.ERA}`);
        console.log(`AVG: ${this.AVG}`);
        console.log(this.batterGameStats);
        console.log(this.pitcherGameStats);
        console.log(`************`);
    }

    addPitcherStats(pitcherID: number, teamID: number, gameID:number){
        // const pitcherStats: PitcherStats = {
        //     id: pitcherID, //Need this to be unique and track it for each player,
        //     game: gameID,
        //     team: teamID,
        //     player: this.id,
        //     player_text: '', //not sure what goes here.
        //     IP: 0.0, // Need to implement changing pitchers, incrementing, and partial innings
        //     H: 0, // Need to increment
        //     R: 0, // Need to implement when they cross plate,
        //     ER: 0, // Need to implement idea of earned runs and errors and such
        //     BB: 0,
        //     SO: 0,
        //     BF: 0, // need to increment.
        //     ERA: 0, // Need to implement
        // }
        // this.pitcherGameStats = pitcherStats;
        // this.updateERA();
    }

    addPlayerAverages(playerAverages:BatterStats){
        // this.batterAverages = playerAverages;
    }

    addPitcherAverages(pitcherAverages:(PitcherStats | null)){
        // this.pitcherAverages = pitcherAverages;
    }

    updatePitcherStats(outcome: OUTCOME, strikeout: boolean){
        // // update the pitcher stats
        // // Only going to do full innings for now
        // //pitcher.pitcherGameStats.IP = pitcher.pitcherGameStats.IP + 1;
        // this.pitcherGameStats.BF = this.pitcherGameStats.BF + 1;
        // if(outcome == OUTCOME.BB){
        //     this.pitcherGameStats.BB = this.pitcherGameStats.BB + 1;
        // }else if(outcome == OUTCOME.OUT && strikeout){
        //     this.pitcherGameStats.SO = this.pitcherGameStats.SO + 1;
        // }else if(outcome == OUTCOME.SINGLE || outcome == OUTCOME.DOUBLE || outcome == OUTCOME.TRIPLE || outcome == OUTCOME.HOME_RUN){
        //     this.pitcherGameStats.H = this.pitcherGameStats.H + 1;
        // }
        // this.updateERA();
    }

    addInningPitched(){
        // this.pitcherGameStats.IP = this.pitcherGameStats.IP + 1;
        // this.totalIP = this.totalIP + 1;
        // this.updateERA();
    }

    addBatterStats(batterID: number, teamID: number, gameID:number){
        // const batterStat: BatterStats = {
        //     id: batterID, // Need a unique number? Need to maintain this for each player
        //     game: gameID,
        //     team: teamID,
        //     player: this.id,
        //     player_text: '', //not sure what goes here
        //     AB: 0, // need to increment this each time,
        //     R: 0, // need to update this each time player scores
        //     H: 0,
        //     RBI: 0, // This needs to be updated when players cross the plate,
        //     '2B':0,
        //     '3B':0,
        //     HR: 0,
        //     BB: 0,
        //     SO: 0,
        //     HP: 0, // NEED TO ADD THIS AS AN OUTCOME - HIT BY PITCH,
        //     SF: 0, // NEED TO ADD THIS AS AN OUTCOME
        //     SB: 0 // NEED TO ADD THIS AS AN OUTCOME
        // }
        // this.batterGameStats = batterStat;
    }
    updateBatterStats(outcome: OUTCOME, strikeout: boolean){
        // // update the batter stats
        // this.batterGameStats.AB = this.batterGameStats.AB + 1;
        // if(outcome == OUTCOME.BB){
        //     this.batterGameStats.BB = this.batterGameStats.BB + 1;
        // }else if(outcome == OUTCOME.OUT && strikeout){
        //     this.batterGameStats.SO = this.batterGameStats.SO + 1;
        // }else if(outcome == OUTCOME.SINGLE || outcome == OUTCOME.DOUBLE || outcome == OUTCOME.TRIPLE || outcome == OUTCOME.HOME_RUN){
        //     this.batterGameStats.H = this.batterGameStats.H + 1;
        //     this.totalHits = this.totalHits + 1;
        // }
        // if(outcome == OUTCOME.DOUBLE){
        //     this.batterGameStats['2B'] = this.batterGameStats['2B'] + 1;
        // }
        // if(outcome == OUTCOME.TRIPLE){
        //     this.batterGameStats['3B'] = this.batterGameStats['3B'] + 1;
        // }
        // if(outcome == OUTCOME.HOME_RUN){
        //     this.batterGameStats.HR = this.batterGameStats.HR + 1;
        // }
        // this.totalAtBats = this.totalAtBats + 1;
        // this.updateBattingAverage();
        // console.log(outcome, strikeout);
    }
    
    async writeBattingStatsToDB(){
        // let sql = `INSERT INTO wp_s4sawz_uspbl_boxscores_batters 
        // (game,team,player,player_text,AB,R,H,2B,3B,HR,RBI,BB,SO,HP,SF,SB) VALUES 
        // (${this.batterGameStats.game},${this.batterGameStats.team},${this.id},'',${this.batterGameStats.AB},
        // ${this.batterGameStats.R},${this.batterGameStats.H},${this.batterGameStats['2B']},
        // ${this.batterGameStats['3B']},${this.batterGameStats.HR},${this.batterGameStats.RBI},
        // ${this.batterGameStats.BB},${this.batterGameStats.SO},${this.batterGameStats.HP},
        // ${this.batterGameStats.SF},${this.batterGameStats.SB})`;
        // // function call and pass the connection and sql query you want to execute
        // const con = mysql.createConnection(connection);
        // const p  = runQuery(con, sql); 
        // const data:any = await p; // promise and callback function
        // console.log(`saved ${this.firstName} ${this.lastName} batting stats`, data);
    }

    async writePitcherStatsToDB(){
        // let sql = `INSERT INTO wp_s4sawz_uspbl_boxscores_pitchers 
        // (game,team,player,player_text,IP,H,R,ER,BB,SO,BF) VALUES 
        // (${this.pitcherGameStats.game},${this.pitcherGameStats.team},${this.id},'',${this.pitcherGameStats.IP},
        // ${this.pitcherGameStats.H},${this.pitcherGameStats.R},${this.pitcherGameStats.ER},
        // ${this.pitcherGameStats.BB},${this.pitcherGameStats.SO},${this.pitcherGameStats.BF})`;
        // // function call and pass the connection and sql query you want to execute
        // const con = mysql.createConnection(connection);
        // const p  = runQuery(con, sql); 
        // const data:any = await p; // promise and callback function
        // console.log(`saved ${this.firstName} ${this.lastName} pitching stats`, data);
    }
}