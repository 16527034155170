
export const enum OUTCOME {
    BB = 'Base on Balls',
    OUT = 'Out',
    SINGLE = 'Single',
    DOUBLE = 'Double',
    TRIPLE = 'Triple',
    HOME_RUN = 'Home Run',
}

export enum POSITION {
    FIRST_BASE = 'First Base',
    SECOND_BASE = 'Second Base',
    SHORT_STOP = 'Short Stop',
    THIRD_BASE = 'Third Base',
    CATCHER = 'Catcher',
    PITCHER = 'Pitcher',
    RIGHT_FIELDER = 'Right Fielder',
    LEFT_FIELDER = 'Left Fielder',
    CENTER_FIELDER = 'Center Fielder'
}

export const enum INNING_HALF {
    TOP = 'Top',
    BOTTOM = 'Bottom'
}

// position to  location conversion 

export const StatisticConversion = {
    'AB': 'At Bats',
    'R': 'Runs',
    'RBI': 'Runs Batted In',
    'H': 'Hits',
    'BB': 'Base on Balls',
    'AVG': 'Batting Average',
    'G': 'Games Played',
    'SLG': 'Slugging Percentage',
    '2B': 'Doubles',
    '3B': 'Triples',
    'HR': 'Home Runs',
    'SB': 'Stolen Bases',
    'SO': 'Strike Outs',
    'IP': 'Innings Pitched',
    'W': 'Wins',
    'L': 'Losses',
    'ER': 'Earned Runs',
    'GS': 'Games Started',
    'CG': 'Complete Games',
    'SHO': 'Shut Outs',
    'SV': 'Saves',
    'ERA': 'Earned Run Average',
    'E': 'Errors',
}

export const enum BATS_THROWS {
    LEFT = 'Left',
    RIGHT = 'Right',
    SWITCH = 'Switch'
}

export const enum TYPES_OF_HIT_BALLS {
    GROUND_BALL = 'grounded out',
    FLY_BALL = 'flied out',
    LINE_DRIVE = 'lined out',
    POP_FLY = 'popped out'
}

export const POSITION_AND_TYPES_OF_HIT_BALLS = {
    [POSITION.FIRST_BASE]: [TYPES_OF_HIT_BALLS.GROUND_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.SECOND_BASE]: [TYPES_OF_HIT_BALLS.GROUND_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.SHORT_STOP]: [TYPES_OF_HIT_BALLS.GROUND_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.THIRD_BASE]: [TYPES_OF_HIT_BALLS.GROUND_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.LEFT_FIELDER]: [TYPES_OF_HIT_BALLS.FLY_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.CENTER_FIELDER]: [TYPES_OF_HIT_BALLS.FLY_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.RIGHT_FIELDER]: [TYPES_OF_HIT_BALLS.FLY_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.PITCHER]: [TYPES_OF_HIT_BALLS.GROUND_BALL, TYPES_OF_HIT_BALLS.LINE_DRIVE, TYPES_OF_HIT_BALLS.POP_FLY],
    [POSITION.CATCHER]: [TYPES_OF_HIT_BALLS.POP_FLY],
}

// LAST NAME SINGLED TO CENTER
// LAST NAME FLIED OUT TO RIGHT
// Strike Looking
// Foul Ball
// Ball
// struck out swinging
// struck out looking
// LAST NAME SINGLED TO CENTER, SAME TO SECOND, LAST NAME TO THIRD
// LAST NAME SCORED

export const enum TYPES_OF_STRIKES {
    STRIKE_LOOKING = 'Strike Looking',
    STRIKE_SWINGING = 'Strike Swinging',
    FOUL_BALL = 'Foul Ball',
}

export const enum TYPES_OF_STRIKE_OUTS {
    STRUCK_OUT_LOOKING = 'struck out looking',
    STRUCK_OUT_SWINGING = 'struck out swinging'
}
// WALKED
export const TYPES_OF_HITS = {
    [OUTCOME.SINGLE] : 'singled',
    [OUTCOME.DOUBLE] : 'doubled',
    [OUTCOME.TRIPLE] : 'tripled',
    [OUTCOME.HOME_RUN] : 'homered',
    [OUTCOME.BB] : 'walked',
}

export const HIT_PROBABILITIES = {
    [OUTCOME.SINGLE]: 0.2,
    [OUTCOME.DOUBLE]: 0.1,
    [OUTCOME.TRIPLE]: 0.05,
    [OUTCOME.HOME_RUN]: 0.01
}

export const OUT_PROBABILITIES_TO_POSITIONS = {
    [POSITION.FIRST_BASE]: 0.07,
    [POSITION.SECOND_BASE]: 0.134,
    [POSITION.SHORT_STOP]: 0.137,
    [POSITION.THIRD_BASE]: 0.094,
    [POSITION.LEFT_FIELDER]: 0.065,
    [POSITION.CENTER_FIELDER]: 0.104,
    [POSITION.RIGHT_FIELDER]: 0.079,
    [POSITION.PITCHER]: 0.048,
    [POSITION.CATCHER]: 0.012,
    [TYPES_OF_STRIKE_OUTS.STRUCK_OUT_LOOKING]: 0.062,
    [TYPES_OF_STRIKE_OUTS.STRUCK_OUT_SWINGING]: 0.195,
}

export const enum PITCH_OUTCOME {
    BALL = 'Ball',
    STRIKE_LOOKING = 'Strike Looking',
    STRIKE_SWINGING = 'Strike Swinging',
    FOUL_BALL = 'Foul Ball',
    IN_PLAY = 'In Play'
}

// calculate player stats based on outcome
// export const calculateBatterStatsAfterBat = async (player: Player, outcome: OUTCOME, boxScoreWords: string, season:number, team: Team, runs: number) => {
//     // get the player's stats for the current season and team
//     let playerStats = player.stats.find((stat) => stat.Season === season && stat.Team === team.name);

//     // if the player has no stats for the current season and team, create them
//     if (!playerStats) {
//         playerStats ={
//             Game: null,
//             Season: season,
//             Team: team.name,
//             AB: 0,
//             R: 0,
//             H: 0,
//             '2B': 0,
//             '3B': 0,
//             HR: 0,
//             RBI: 0,
//             SB: 0,
//             BB: 0,
//             SO: 0,
//             E: 0,
//             SLG: 0,
//             AVG: 0,
//         };
//     }

//     //update the player's stats based on the outcome
//     playerStats.AB += 1;
//     if (outcome === OUTCOME.BB) {
//         playerStats.BB += 1;
//     }
//     else if (outcome === OUTCOME.OUT) {
//         if(boxScoreWords === TYPES_OF_STRIKE_OUTS.STRUCK_OUT_LOOKING || boxScoreWords === TYPES_OF_STRIKE_OUTS.STRUCK_OUT_SWINGING) {
//             playerStats.SO += 1;
//         }
//     } else if ( outcome === OUTCOME.SINGLE) {
//         playerStats.H += 1;
//     }else if (outcome === OUTCOME.DOUBLE) {
//         playerStats.H += 1;
//         playerStats['2B'] += 1;
//     }else if (outcome === OUTCOME.TRIPLE) {
//         playerStats.H += 1;
//         playerStats['3B'] += 1;
//     }else if (outcome === OUTCOME.HOME_RUN) {
//         playerStats.H += 1;
//         playerStats.HR += 1;
//     }

//     playerStats.RBI += runs;

//     // calculate batting average
//     player.AVG = playerStats.H / playerStats.AB;
//     playerStats.AVG = player.AVG;
//     // calculate slugging percentage
//     playerStats.SLG = (playerStats.H + playerStats['2B'] + (2 * playerStats['3B']) + (3 * playerStats.HR)) / playerStats.AB;
//     // TODO: SAVE
//     await savePlayer(player);
// }

// export const calculatePitcherStatsAfterBat = async (pitcher: Player, outcome: OUTCOME, boxScoreWords: string, season:number, team: Team, runs: number) => {
//     // get the pitcher's stats for the current season and team
//     let pitcherStats = pitcher.pitcherStats.find((stat) => stat.YR === season && stat.Team === team.name);

//     // if the pitcher has no stats for the current season and team, create them
//     if (!pitcherStats) {
//         pitcherStats ={
//             Game: null,
//             YR: season,
//             Team: team.name,
//             G: 0,
//             IP: 0,
//             W: 0,
//             L: 0,
//             R: 0,
//             ER: 0,
//             SO: 0,
//             BB: 0,
//             GS: 0, 
//             CG: 0,
//             SHO: 0,
//             SV: 0,
//             ERA: 0,
//             AVG: 0,
//         };
//     }

//     //update the pitcher's stats based on the outcome
//     //pitcherStats.IP += 1;
//     if (outcome === OUTCOME.BB) {
//         pitcherStats.BB += 1;
//     }   
//     else if (outcome === OUTCOME.OUT) {
//         if(boxScoreWords === TYPES_OF_STRIKE_OUTS.STRUCK_OUT_LOOKING || boxScoreWords === TYPES_OF_STRIKE_OUTS.STRUCK_OUT_SWINGING) {
//             pitcherStats.SO += 1;
//         }
//     }

//     await savePlayer(pitcher);
// }


// export const savePlayer = async(player: Player) => {
//     // TODO Save player to database
// }

export const OUTS_AND_POSITIONS = {
    [TYPES_OF_HIT_BALLS.GROUND_BALL]: [POSITION.FIRST_BASE, POSITION.SECOND_BASE, POSITION.SHORT_STOP, POSITION.THIRD_BASE],
    [TYPES_OF_HIT_BALLS.FLY_BALL]: [POSITION.LEFT_FIELDER, POSITION.CENTER_FIELDER, POSITION.RIGHT_FIELDER],
    [TYPES_OF_HIT_BALLS.LINE_DRIVE]: [POSITION.FIRST_BASE, POSITION.SECOND_BASE, POSITION.SHORT_STOP, POSITION.THIRD_BASE, POSITION.LEFT_FIELDER, POSITION.CENTER_FIELDER, POSITION.RIGHT_FIELDER],
    [TYPES_OF_HIT_BALLS.POP_FLY]: [POSITION.FIRST_BASE, POSITION.SECOND_BASE, POSITION.SHORT_STOP, POSITION.THIRD_BASE, POSITION.LEFT_FIELDER, POSITION.CENTER_FIELDER, POSITION.RIGHT_FIELDER]
}

export const LOCATIONS_OF_HIT_BALLS = [
    'left field',
    'left center field',
    'center field',
    'right center field',
    'right field',
    'left field line',
    'right field line',
]  

export const enum LOCATION_OF_PITCHES {
    INSIDE = 'Inside',
    OUTSIDE = 'Outside',
    DOWN = 'Down',
    UP = 'Up',
    MIDDLE = 'Middle'
}

export const enum TYPES_OF_PITCHES {
    FASTBALL = 'Fastball',
    CURVEBALL = 'Curveball',
    SLIDER = 'Slider',
    SINKER = 'Sinker',
    CHANGEUP = 'Changeup',
    CUTTER = 'Cutter',
    FORKBALL = 'Forkball',
    KNUCKLEBALL = 'Knuckleball',
    SCREWBALL = 'Screwball',
    SPLITTER = 'Splitter',
    PALMBALL = 'Palmball',
}